// core
import React, { ReactElement } from 'react';
import cx from 'classnames';
import { computePrices } from '..';
// typescript types
import { BillingPeriod } from '..';
type Props = {
  period: BillingPeriod;
  activePeriod: BillingPeriod;
  activeNumberOfParallelSessions: number;
  setActivePeriod: Function;
};

function RadioButtonPeriod({
  period,
  activePeriod,
  activeNumberOfParallelSessions,
  setActivePeriod,
}: Props): ReactElement {
  // check if this radio button active
  const isActive = activePeriod === period;

  // compute CSS classes
  const radiobuttonClasses = cx('pricing__radiobuttons-period-item', { active: isActive });

  const label = defineLabel(period);
  const { price, priceWithoutDiscount } = computePrices(
    period,
    'live plus',
    activeNumberOfParallelSessions
  );

  return (
    <label className={radiobuttonClasses}>
      <span className="pricing__radiobuttons-period-item-label">Billed {label}</span>
      {period === 'year' && (
        <span className="pricing__radiobuttons-period-item-priceWithoutDiscount">
          ${priceWithoutDiscount}/{period}
        </span>
      )}
      <span className="pricing__radiobuttons-period-item-price">
        ${price}/{period}
      </span>
      <input
        className="pricing__radiobuttons-period-item-input"
        type="radio"
        checked={isActive}
        name="period"
        onChange={() => setActivePeriod(period)}
      />
    </label>
  );
}

export default React.memo(RadioButtonPeriod);

function defineLabel(period: BillingPeriod): string {
  let label: string;

  switch (period) {
    case 'month':
      label = 'monthly';
      break;

    case 'year':
      label = 'annually';
      break;
  }

  return label;
}
