// core
import React, { ReactElement } from 'react';
import cx from 'classnames';
// typescript types
type Props = {
  nummberOfParallelSessions: number;
  activeNumberOfParallelSessions: number;
  setActiveNumberOfParallelSessions: Function;
};

function RadioButtonParallelSession({
  nummberOfParallelSessions,
  activeNumberOfParallelSessions,
  setActiveNumberOfParallelSessions,
}: Props): ReactElement {
  // check if this radio button active
  const isActive = activeNumberOfParallelSessions === nummberOfParallelSessions;

  // compute CSS classes
  const radiobuttonClasses = cx('pricing__radiobuttons-parallel-sessions-item', {
    active: isActive,
  });

  return (
    <label className={radiobuttonClasses}>
      {nummberOfParallelSessions}
      <input
        className="pricing__radiobuttons-parallel-sessions-item-input"
        type="radio"
        checked={isActive}
        name="parallel-sessions"
        onChange={() => setActiveNumberOfParallelSessions(nummberOfParallelSessions)}
      />
    </label>
  );
}

export default React.memo(RadioButtonParallelSession);
