export const accountTabsStyles = {
  root: 'account__tabs',
  indicator: 'account__tabs-indicator',
  flexContainer: 'account__tabs-flexContainer',
};

export const accountTabStyles = {
  root: 'account__tabs-item',
  selected: 'account__tabs-item_selected',
};
