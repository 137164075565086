// core
import React, { ReactElement } from 'react';
// typescript types
type Props = {
  children: any;
  value: number;
  index: number;
  className: string;
};

function TabPanel({ children, value, index, className }: Props): ReactElement {
  if (value !== index) return null;

  return <div className={className}>{children}</div>;
}

export default React.memo(TabPanel);
