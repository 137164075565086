// core
import React, { useState, ReactElement, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
// components
import { accountTabsStyles, accountTabStyles } from './stylesAndSettings';
import TabPanel from './components/tabPanel';
import RadioButtonPeriod from './components/radiobuttonperiod';
import RadioButtonParallelSession from './components/radiobuttonparallelsession';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import { useAuth0 } from '../../helpers/auth';
import { computeMainBlockHeightWithAlerts, disableScroll, getCookie } from '../../helpers';
//default settings
import {
  checkoutOperator,
  app_metadata_namespace,
  billingOperatorForPurchase as billingOperator,
} from '../../settings.json';
// images
import Images from './images';
// styles
import './pageAccount.scss';
// typescript types
import { RootState } from '../../store/rootReducer';
export type BillingPeriod = 'month' | 'year';

const PageAccount: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const [popupActive, setPopupActive] = useState(false);
  const [activeNumberOfParallelSessions, setActiveNumberOfParallelSessions] = useState(
    parallelSessions[0]
  );
  const [activePeriod, setActivePeriod] = useState<BillingPeriod>(billingPeriods[0]);
  const isThanksForPurchaseAlertActive = useSelector(
    (state: RootState) => state.ui.isThanksForPurchaseAlertActive
  );
  const isPlanActiveAlertActive = useSelector(
    (state: RootState) => state.ui.isPlanActiveAlertActive
  );
  const isPaymentDeatilsUpdatedAlertActive = useSelector(
    (state: RootState) => state.ui.isPaymentDeatilsUpdatedAlertActive
  );
  const userDoesntHavePlan = useSelector((state: RootState) => state.ui.userDoesntHavePlan);
  const accountPageTabsValue = useSelector((state: RootState) => state.ui.accountPageTabsValue);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const isMobile = useMediaQuery({ maxWidth: 786 }); // check if screen width is less than or equal to 768px
  const isXSMobile = useMediaQuery({ maxWidth: 576 }); // check if screen width is less than or equal to 576px
  const { user, setUser, getUser, isAppSumoAlertActive } = useAuth0();

  // compute CSS classes
  const popupClasses = cx('billing__popup', { active: popupActive });

  // compute CSS height of main block
  const style = computeMainBlockHeightWithAlerts({
    isXSMobile,
    isThanksForPurchaseAlertActive,
    isPlanActiveAlertActive,
    isPaymentDeatilsUpdatedAlertActive,
    isAppSumoAlertActive,
  });

  // destructuring app metadata
  let {
    next_renewal_date,
    license_code,
    next_renewal_price,
    status,
    next_renewal_currency,
    expiration_date,
    recurring,
    billing_operator,
    cancel_url,
    update_url,
    is_trial,
  } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

  // destructuring user limits and stats data
  let {
    license_short_name,
    license_period_group,
    license_period,
    license_subscription_screens_count: screensLimit,
    license_subscription_vnc_count: vncLimit,
  } = userLimitsAndStats;
  license_period_group = license_period_group?.toLowerCase();

  // label in "Type" row in Billing
  const billingPeriodLabel =
    license_period > 1 ? `${license_period} ${license_period_group}s` : license_period_group; // 2 months or month

  // if 'Subscription status' is 'active' - true, else - false
  const isSubscriptionActive = status?.toLowerCase() === 'active';

  // current operator which was used for payment - we need this variable in order to switch
  // between operators 'paddle', 'reseller' and '2checkout'
  // 'avangate' means that it is '2checkout'
  let operator: string;
  switch (billing_operator?.toLowerCase()) {
    case 'paddle':
      operator = 'paddle';
      break;

    case 'reseller':
      operator = 'reseller';
      break;

    case 'avangate':
      operator = '2checkout';
      break;
  }

  // getting day, month, year for "Next renewal date"
  let date = recurring == '1' ? next_renewal_date : expiration_date;
  date = new Date(date?.split(' ')[0]);
  const month = date.toLocaleString('en', { month: 'long' });
  const year = date.getFullYear();
  const day = date.getDate();
  const nextDate = `${day} ${month} ${year}`;

  // link to customer's 2checkout profile
  const linkTo2checkoutProfile = `https://secure.2co.com/myaccount/my_license/?code=${license_code}`;

  function openCheckout(event, planName: string) {
    event.preventDefault();
    disableScroll(true);

    let product: number;
    const parallelTests = activeNumberOfParallelSessions;

    switch (planName) {
      case 'live plus':
        product =
          checkoutOperator[billingOperator][planName]['parallelTests'][parallelTests][activePeriod];
        break;
    }

    const passthrough = {};
    passthrough.user_id = user?.sub;
    const aff_offer_id = getCookie('aff_offer_id');
    const aff_click_id = getCookie('aff_click_id');

    if (aff_offer_id) {
      passthrough.aff_offer_id = aff_offer_id;
    }

    if (aff_click_id) {
      passthrough.aff_click_id = aff_click_id;
    }

    Paddle.Checkout.open({
      product,
      email: user?.email,
      passthrough,
      successCallback,
      closeCallback,
    });

    function successCallback() {
      dispatch(uiActions.toggleThanksForPurchasePopup(true));
      disableScroll(false);
    }

    function closeCallback() {
      disableScroll(false);
    }
  }

  function editPaymentDetailsPaddle() {
    disableScroll(true);

    Paddle.Checkout.open({
      override: update_url,
      successCallback: editPaymentDetailsPaddleSuccessCallback,
      closeCallback,
    });
  }

  function cancelSubscriptionPaddle() {
    disableScroll(true);
    setPopupActive(false);

    Paddle.Checkout.open({
      override: cancel_url,
      successCallback: cancelSubscriptionPaddleSuccessCallback,
      closeCallback,
    });
  }

  function closeCallback() {
    disableScroll(false);
  }

  function editPaymentDetailsPaddleSuccessCallback() {
    navigate('/');
    dispatch(uiActions.togglePaymentDeatilsUpdatedAlert(true));
    setTimeout(() => dispatch(uiActions.togglePaymentDeatilsUpdatedAlert(false)), 10000);
    disableScroll(false);
  }

  let timer;

  async function cancelSubscriptionPaddleSuccessCallback() {
    const user = await getUser();
    let { status } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    if (status?.toLowerCase() === 'canceled') {
      setUser(user);
    } else {
      timer = setInterval(checkIfSubscriptionCancelled, 5000);
    }

    disableScroll(false);
  }

  async function checkIfSubscriptionCancelled() {
    const user = await getUser();
    let { status } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    if (status?.toLowerCase() === 'canceled') {
      setUser(user);
      clearInterval(timer);
    }
  }

  function handleAccountTabsChange(event, newValue) {
    dispatch(uiActions.toggleAccountPageTabsValue(newValue));
  }

  function navigateToPricingTab() {
    dispatch(uiActions.toggleAccountPageTabsValue(1));
  }

  let planDetailsItemStyles = {};

  if (isMobile) {
    if (userDoesntHavePlan) {
      planDetailsItemStyles = {
        width: '100%',
        borderRight: 'none',
      };
    } else {
      planDetailsItemStyles = {
        width: '50%',
        flexDirection: 'column',
        alignItems: 'flex-start',
      };
    }
  }

  const billingJSX = (
    <div className="container_short">
      <h2 className="billing__subheading first">Plan Details</h2>
      <div className="billing__plan-details">
        <div className="billing__plan-details-item" style={planDetailsItemStyles}>
          <div className="billing__plan-details-item-1">Current plan’s name:</div>
          <div className="billing__plan-details-item-2">
            {license_short_name} {is_trial == 1 ? '(Trial)' : ''}
          </div>
        </div>
        {!userDoesntHavePlan && (
          <div className="billing__plan-details-item" style={planDetailsItemStyles}>
            <div className="billing__plan-details-item-1">Type:</div>
            <div className="billing__plan-details-item-2">{billingPeriodLabel}</div>
          </div>
        )}
        {userDoesntHavePlan ? null : recurring === '1' ? (
          <>
            <div className="billing__plan-details-item" style={planDetailsItemStyles}>
              <div className="billing__plan-details-item-1">Next renewal date:</div>
              <div className="billing__plan-details-item-2">
                {next_renewal_date ? nextDate : ''}
              </div>
            </div>
            <div className="billing__plan-details-item" style={planDetailsItemStyles}>
              <div className="billing__plan-details-item-1">Next payment due:</div>
              <div className="billing__plan-details-item-2">
                {`${next_renewal_price || ''} ${next_renewal_currency || ''}`}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="billing__plan-details-item" style={planDetailsItemStyles}>
              <div className="billing__plan-details-item-1">Renewal:</div>
              <div className="billing__plan-details-item-2">Disabled</div>
            </div>
            <div className="billing__plan-details-item" style={planDetailsItemStyles}>
              <div className="billing__plan-details-item-1">Expiration date:</div>
              <div className="billing__plan-details-item-2">{expiration_date ? nextDate : ''}</div>
            </div>
          </>
        )}
        {!userDoesntHavePlan && (
          <div className="billing__plan-details-item" style={planDetailsItemStyles}>
            <div className="billing__plan-details-item-1">Subscription status:</div>
            <div className="billing__plan-details-item-2">
              {!isSubscriptionActive && <Images.exclamation />} {status}
            </div>
          </div>
        )}
        {isSubscriptionActive && ['2checkout', 'paddle'].includes(operator) ? (
          <div className="billing__plan-details-subscription-active">
            <button
              className="billing__plan-details-subscription-active-link"
              onClick={() => setPopupActive(true)}
            >
              Cancel Subscription
            </button>
          </div>
        ) : !isSubscriptionActive && operator === '2checkout' ? (
          <div className="billing__plan-details-subscription-inactive">
            {defineSubscriptionStatusText(status)}
            <a
              href={linkTo2checkoutProfile}
              className="billing__plan-details-subscription-inactive-link"
            >
              Activate Subscription
            </a>
          </div>
        ) : null}
      </div>
      {userDoesntHavePlan ? (
        <div className="billing__upgrade-offer">
          <h2 className="billing__upgrade-offer-heading">Don’t miss it. Get more!</h2>
          <div className="billing__upgrade-offer-lists">
            <ul className="billing__upgrade-offer-list">
              <li className="billing__upgrade-offer-list-item">
                <Images.check /> More than one parallel test
              </li>
              <li className="billing__upgrade-offer-list-item">
                <Images.check /> Login Profile tool
              </li>
              <li className="billing__upgrade-offer-list-item">
                <Images.check /> Unlimited number of screenshots per session
              </li>
            </ul>
            <ul className="billing__upgrade-offer-list">
              <li className="billing__upgrade-offer-list-item">
                <Images.check /> Unlimited live sessions per month
              </li>
              <li className="billing__upgrade-offer-list-item">
                <Images.check /> Any resolution you need
              </li>
              <li className="billing__upgrade-offer-list-item">
                <Images.check /> Unlimited number of screenshots per month
              </li>
            </ul>
          </div>
          <button
            className="billing__upgrade-offer-upgrade-btn orange-gradient-btn two-border-active"
            onClick={navigateToPricingTab}
          >
            Upgrade
          </button>
        </div>
      ) : (
        <>
          <h2 className="billing__subheading second">Payment Details</h2>
          <div className="billing__payment-details">
            <p className="billing__payment-details-text">
              No personal information is stored to ensure better security. All changes in the
              payment method are made through the billing operator.
            </p>
            {operator === '2checkout' && (
              <a
                href={linkTo2checkoutProfile}
                className="billing__payment-details-link"
                target="_blank"
              >
                Edit payment method
              </a>
            )}
            {operator === 'paddle' && isSubscriptionActive && update_url && (
              <button className="billing__payment-details-link" onClick={editPaymentDetailsPaddle}>
                Edit payment method
              </button>
            )}
          </div>
        </>
      )}
      <div className={popupClasses}>
        <div className="billing__popup-content">
          <button className="billing__popup-btn-close" onClick={() => setPopupActive(false)}>
            &times;
          </button>
          <p className="billing__popup-heading">Cancel subscription</p>
          {operator === '2checkout' && (
            <>
              <p className="billing__popup-text">
                By canceling your subscription you will return to the restrictions providing by
                Guest Plan as soon as the current billing period ends.
              </p>
              <p className="billing__popup-text">
                To cancel subscription log in to 2Checkout Personal Account. Are you sure you want
                to cancel?
              </p>
            </>
          )}
          {operator === 'paddle' && (
            <p className="billing__popup-text">
              By canceling your subscription you will return to the restrictions provided by Guest
              Plan as soon as the current billing period ends. Are you sure you want to cancel your
              subscription?
            </p>
          )}
          <div className="billing__popup-buttons">
            {operator === '2checkout' && (
              <>
                <button className="billing__popup-btn-no" onClick={() => setPopupActive(false)}>
                  {isXSMobile ? ' Go Back' : 'No, Go Back'}
                </button>
                <a
                  href={linkTo2checkoutProfile}
                  target="_blank"
                  className="billing__popup-btn-yes"
                  onClick={() => setPopupActive(false)}
                >
                  {isXSMobile ? ' Confirm' : 'Yes, Go to 2Checkout'}
                </a>
              </>
            )}
            {operator === 'paddle' && (
              <>
                <button className="billing__popup-btn-no" onClick={() => setPopupActive(false)}>
                  Keep Plan
                </button>
                <button className="billing__popup-btn-yes" onClick={cancelSubscriptionPaddle}>
                  {isXSMobile ? ' Confirm' : 'Cancel Subscription'}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <main className="account" style={style}>
      <h1 className="account__heading">Account Settings</h1>
      <div className="account__content">
        {userDoesntHavePlan ? (
          <>
            <Tabs
              value={accountPageTabsValue}
              onChange={handleAccountTabsChange}
              classes={accountTabsStyles}
            >
              <Tab label="Billing" classes={accountTabStyles} disableRipple />
              <Tab label="Pricing" classes={accountTabStyles} disableRipple />
            </Tabs>
            <TabPanel value={accountPageTabsValue} index={0} className="account__tabpanel">
              {billingJSX}
            </TabPanel>
            <TabPanel value={accountPageTabsValue} index={1} className="account__tabpanel">
              <div className="container_620">
                <h2 className="pricing__heading">Live Plus Plan</h2>
                <ul className="pricing__features">
                  <li className="pricing__features-item">
                    <Images.camera />
                    <div className="pricing__features-item-limits">
                      <p className="pricing__features-item-limits-now">
                        {screensLimit} screenshots/month
                      </p>
                      <p className="pricing__features-item-limits-then">Unlimited screenshots</p>
                    </div>
                  </li>
                  <li className="pricing__features-item">
                    <Images.pointer />
                    <div className="pricing__features-item-limits">
                      <p className="pricing__features-item-limits-now">
                        {vncLimit} live sessions/month
                      </p>
                      <p className="pricing__features-item-limits-then">Unlimited live sessions</p>
                    </div>
                  </li>
                </ul>
                <div className="pricing__main-block">
                  <ul className="pricing__selectors">
                    <li className="pricing__selectors-item">
                      <p className="pricing__selectors-item-heading">
                        1. Choose your billing cycle
                      </p>
                      <div className="pricing__radiobuttons-period">
                        {billingPeriods.map((period) => (
                          <RadioButtonPeriod
                            key={period}
                            period={period}
                            activePeriod={activePeriod}
                            activeNumberOfParallelSessions={activeNumberOfParallelSessions}
                            setActivePeriod={setActivePeriod}
                          />
                        ))}
                      </div>
                    </li>
                    <li className="pricing__selectors-item">
                      <p className="pricing__selectors-item-heading">
                        2. Number of parallel sessions
                      </p>
                      <div className="pricing__radiobuttons-parallel-sessions">
                        {parallelSessions.map((number) => (
                          <RadioButtonParallelSession
                            key={number}
                            nummberOfParallelSessions={number}
                            activeNumberOfParallelSessions={activeNumberOfParallelSessions}
                            setActiveNumberOfParallelSessions={setActiveNumberOfParallelSessions}
                          />
                        ))}
                      </div>
                      <div className="pricing__parallel-sessions"></div>
                      <p className="pricing__selectors-item-text">
                        Parallel sessions indicate the number of tests that can be performed
                        simultaneously
                      </p>
                    </li>
                  </ul>
                  <div className="pricing__final">
                    {definePriceJSX(activePeriod, 'live plus', activeNumberOfParallelSessions)}
                    <button
                      className="pricing__final-upgrade-btn orange-gradient-btn two-border-active"
                      onClick={(event) => openCheckout(event, 'live plus')}
                    >
                      Upgrade
                    </button>
                  </div>
                </div>
                <p className="pricing__notification">
                  Subscription will be auto-renewed on the first day of your next billing period
                  using the specified payment method unless you cancel auto-renewal in your billing
                  preferences. In this case, once your subscription period ends you will be switched
                  to free mode with limited functionality.
                </p>
              </div>
            </TabPanel>
          </>
        ) : (
          billingJSX
        )}
      </div>
    </main>
  );
};

export default PageAccount;

const parallelSessions = [1, 2, 4, 10];

const billingPeriods: BillingPeriod[] = ['year', 'month'];

const monthlyPrices = {
  'live plus': {
    '1': 14.99,
    '2': 26.99,
    '4': 49,
    '10': 95,
  },
};

export function computePrices(
  period: BillingPeriod,
  licenseType: string,
  activeNumberOfParallelSessions?: number
) {
  let price: number, priceSave: number, priceWithoutDiscount: number;

  switch (period) {
    case 'month':
      switch (licenseType) {
        case 'live plus':
          price = monthlyPrices[licenseType][activeNumberOfParallelSessions];
          break;
      }
      break;

    case 'year':
      switch (licenseType) {
        case 'live plus':
          price = Math.floor(monthlyPrices[licenseType][activeNumberOfParallelSessions] * 10);
          priceSave = monthlyPrices[licenseType][activeNumberOfParallelSessions] * 2;
          priceWithoutDiscount = monthlyPrices[licenseType][activeNumberOfParallelSessions] * 12;

          if (activeNumberOfParallelSessions === 1 || activeNumberOfParallelSessions === 2) {
            priceSave = (priceSave * 100 + 0.9 * 100) / 100; // we need to add 0.9 because we remove digits after the point for one parallel test and two parallel tests
          }
          break;
      }
      break;
  }

  return { price, priceSave, priceWithoutDiscount };
}

function definePriceJSX(
  period: BillingPeriod,
  licenseType: string,
  activeNumberOfParallelSessions?: number
) {
  const { price, priceSave } = computePrices(period, licenseType, activeNumberOfParallelSessions);

  switch (period) {
    case 'month':
      return (
        <div className="pricing__final-wrapper">
          <p className="pricing__final-price">
            <span className="pricing__final-price-dollar-sign">$</span>
            <span className="pricing__final-price-digit">{price}</span>
          </p>
          <p className="pricing__final-period">per month</p>
        </div>
      );

    case 'year':
      return (
        <div className="pricing__final-wrapper">
          <p className="pricing__final-price">
            <span className="pricing__final-price-dollar-sign">$</span>
            <span className="pricing__final-price-digit">{price}</span>
          </p>
          <p className="pricing__final-period">per year</p>
          <p className="pricing__final-save-amount">SAVE ${priceSave}</p>
        </div>
      );
  }
}

// define which text to display depending on 'Subscription status'
function defineSubscriptionStatusText(status: string): ReactElement {
  switch (status) {
    case 'ACTIVE':
      return null;

    case 'CANCELED':
      return (
        <p>
          Your subscription is inactive. All the features are still available until a billing cycle
          end date. After that, most of them will be disabled for further use. You will be able to
          resubscribe again after logging into your Comparium account once the current subscription
          ends.
        </p>
      );

    case 'EXPIRED':
      return (
        <p>
          Your subscription is currently inactive. To continue using Comparium PRO features renew
          your subscription through Personal Account of billing operator 2Checkout. Also, you can
          choose the auto-renewal option to have access to all the features without interruptions.
        </p>
      );

    case 'PASTDUE':
      return (
        <p>
          Your subscription appears as past due now as the payment was not made before the
          expiration deadline. You can still renew your subscription at any time through Personal
          Account of billing operator 2Checkout.
        </p>
      );

    case 'PENDING_ACTIVATION':
      return (
        <p>
          The payment process hasn’t been completed yet. It may take some time for your PRO
          subscription to be active. If after 48 hours its status is still “Pending Activation”,
          please contact 2Checkout Support Team for further info.
        </p>
      );
  }
}
